var render, staticRenderFns
import script from "./DocsFileBrowser.vue?vue&type=script&lang=js"
export * from "./DocsFileBrowser.vue?vue&type=script&lang=js"
import style0 from "./DocsFileBrowser.vue?vue&type=style&index=0&id=5d991c21&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d991c21",
  null
  
)

export default component.exports